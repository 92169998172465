button {
    background: none;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    user-select: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

.button, .button:visited {
    border: 2px solid transparent;
	text-decoration: none;
	display: inline-flex;
    justify-content: center;
    align-items: center;
	cursor: pointer;
	outline: none;
	text-align: center;
	font-family: $main-font;
    @include resp(font-size, 20px, 30px, 360px, 1600px);
	font-weight: 600;
	line-height: 1.125;
	color: $c-white;
	background-color: $c-theme;
	vertical-align: bottom;
	border-radius: 9999px;
	user-select: none;
	transition: background-color .2s, border-color .2s, color .2s;

    &::-moz-focus-inner {
        padding:0;
        border:0 none;
    }

    &:hover, &:active {
        text-decoration: none;
        background-color: $c-theme-dark;
    }

    &:focus {
        box-shadow: 0 0 0 2px rgba($c-theme,.3);
    }

    &[disabled] {
        cursor: auto;
        background-color: $c-gray80;
        border-color: $c-gray80;
        color: darken($c-white,20);

        &:hover, &:active {
            background-color: $c-gray80;
            border-color: $c-gray80;
            color: darken($c-white,20);
        }
    }

    &.disabled {
        cursor: auto;
        pointer-events: none;
    }

    span {
		display: inline-block;
		vertical-align: bottom;
        padding: .75em 2.5em;
    }

    i {
        line-height: 20px;
        font-size: .75em;

        &:first-child {
            margin: 0 .5em 0 0;
        }

        &:last-child {
            margin: 0 0 0 .5em;
        }

        &:only-child {
            font-size: 1.125em;
            margin: 0;
        }
    }

    &.full {
        width: 100%;
    }
}

.button--submit {
    display: flex;
    font-size: 1.5em;
    line-height: 1;
    width: 100%;
    border-radius: 9999px;
    letter-spacing: .025em;

    span {
        padding-top: 22px;
        padding-bottom: 22px;
    }
}

.button--loading {
    position: relative;

    &::after {
        animation: loadingRotate 1s infinite linear;
        border: 3px solid $c-text;
        border-left: 3px solid $c-white;
        border-radius: 50%;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 36px;
        height: 36px;
        z-index: 10;
    }
}

.form__button-row {
	display:flex;
	flex-wrap: wrap;
	align-items: center;
	margin:2.375em -.625em -.625em;

	&:first-child {
		margin-top: -.41667em;
	}

	.form-group + & {
		margin-top: 0; // 1.125em
	}

	> * {
		margin: .41667em;
	}

	&.center {
		justify-content: center;
	}

	&.eq-w {
		> * {
			flex:1 0 100%;
			max-width: 320px;

			.button {
				width:100%;
				margin: 0;
			}
		}
	}

	.r-align {
		margin-left:auto;
	}

	+ small {
		margin-top: .5em;
		color: $c-gray9;
	}
}
