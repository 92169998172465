
/*! gravitons */

/*
  v1.0.0 (customized)
  http://jxnblk.com/gravitons
*/
.m0  { margin: 0 }
.mt0 { margin-top: 0 !important;}
.mr0 { margin-right: 0 }
.mb0 { margin-bottom: 0 !important; }
.ml0 { margin-left: 0 !important; }

.m1  { margin: .5rem }
.mt1 { margin-top: .5rem }
.mr1 { margin-right: .5rem }
.mb1 { margin-bottom: .5rem }
.ml1 { margin-left: .5rem }

.m2  { margin: 1rem }
.mt2 { margin-top: 1rem }
.mr2 { margin-right: 1rem }
.mb2 { margin-bottom: 1rem }
.ml2 { margin-left: 1rem }

.m3  { margin: 2rem }
.mt3 { margin-top: 2rem }
.mr3 { margin-right: 2rem }
.mb3 { margin-bottom: 2rem !important; }
.ml3 { margin-left: 2rem }

.m4  { margin: 4rem }
.mt4 { margin-top: 4rem }
.mr4 { margin-right: 4rem }
.mb4 { margin-bottom: 4rem }
.ml4 { margin-left: 4rem }

.mxn1 { margin-left: -.5rem; margin-right: -.5rem }
.mxn2 { margin-left: -1rem; margin-right: -1rem }
.mxn3 { margin-left: -2rem; margin-right: -2rem }
.mxn4 { margin-left: -4rem; margin-right: -4rem }
.my1 {margin-top: .5rem !important; margin-bottom: .5rem !important;}
.my2 {margin-top: 1rem !important; margin-bottom: 1rem !important;}
.my3 {margin-top: 2rem !important; margin-bottom: 2rem !important;}
.my4 {margin-top: 4rem !important; margin-bottom: 4rem !important;}
.mx-auto {margin-left:auto !important; margin-right: auto !important;}
.p0  { padding: 0 }
.pt0 { padding-top: 0 !important; }
.pr0 { padding-right: 0 }
.pb0 { padding-bottom: 0 !important; }
.pl0 { padding-left: 0 }
.py0 { padding-top: 0 !important; padding-bottom: 0 !important; }

.p1  { padding: .5rem }
.pt1 { padding-top: .5rem }
.pr1 { padding-right: .5rem }
.pb1 { padding-bottom: .5rem !important }
.pl1 { padding-left: .5rem }
.px1 { padding-left: .5rem; padding-right: .5rem }
.py1 { padding-top: .5rem; padding-bottom: .5rem }

.p2  { padding: 1rem }
.pt2 { padding-top: 1rem }
.pr2 { padding-right: 1rem }
.pb2 { padding-bottom: 1rem !important }
.pl2 { padding-left: 1rem }
.px2 { padding-left: 1rem; padding-right: 1rem }
.py2 { padding-top: 1rem; padding-bottom: 1rem }

.p3  { padding: 2rem }
.pt3 { padding-top: 2rem }
.pr3 { padding-right: 2rem }
.pb3 { padding-bottom: 2rem !important }
.pl3 { padding-left: 2rem }
.px3 { padding-left: 2rem; padding-right: 2rem }
.py3 { padding-top: 2rem; padding-bottom: 2rem }

.p4  { padding: 4rem }
.pt4 { padding-top: 4rem }
.pr4 { padding-right: 4rem }
.pb4 { padding-bottom: 4rem }
.pl4 { padding-left: 4rem }
.px4 { padding-left: 4rem; padding-right: 4rem }
.py4 { padding-top: 4rem; padding-bottom: 4rem }

/** display helpers **/

.db  { display: block; width: 100%; }
.dib { display: inline-block; }
.di  { display: inline; }
.dt  { display: table; }
.dtc { display: table-cell; }
.d-flex { display: flex !important; }
.fl, .pull-left { float: left; }
.fr, .pull-right { float: right; }
.oh { overflow: hidden; }
.hidden, .hide {
	display:none;
}
*[hidden] {display:none !important;}
.invisible {visibility:hidden;}

.c1  { box-sizing: border-box; width: 8.33333% }
.c2  { box-sizing: border-box; width: 16.66667% }
.c3  { box-sizing: border-box; width: 25% }
.c4  { box-sizing: border-box; width: 33.33333% }
.c5  { box-sizing: border-box; width: 41.66667% }
.c6  { box-sizing: border-box; width: 50% }
.c7  { box-sizing: border-box; width: 58.33333% }
.c8  { box-sizing: border-box; width: 66.66667% }
.c9  { box-sizing: border-box; width: 75% }
.c10 { box-sizing: border-box; width: 83.33333% }
.c11 { box-sizing: border-box; width: 91.66667% }
.c12 { box-sizing: border-box; width: 100% }

/** other helper classes **/

.clear {clear:both;}
.clearfix, .cf {@include clearfix;}

.lalign, .text-left {text-align:left !important;}
.ralign, .text-right {text-align:right !important;}
.center, .text-center {text-align:center !important;}
.vabottom {vertical-align:bottom;}
.vamiddle {vertical-align:middle;}
.rel {position: relative;}

/** text helpers **/

.uc {text-transform: uppercase;}

.success, .green {color:$c-success !important;}
.warning {color:$c-warning !important;}
.error, .red {color:$c-error !important;}
.hl {color:$c-dark !important;}

/* responsive helpers */
.hide-mobile, .mobile-hide {
	@media #{$bp-max-laptop-m} {
		display: none !important;
	}
}

.hide-desktop, .desktop-hide {
	@media #{$bp-laptop-m} {
		display: none !important;
	}
}

$props: (mt: 'margin-top', ml: 'margin-left', mb: 'margin-bottom', mr: 'margin-right', pt: 'padding-top', pl: 'padding-left', pb: 'padding-bottom', pr: 'padding-right');

@each $prop in $props {
    @for $i from 0 to 20 {
        $counter: 5 * $i;

        .#{nth($prop, 1)}-#{$counter} {
            #{nth($prop, 2)}: unquote(#{$counter}px) !important;
        }
    }
}
