/* ---- Other ---- */
$page-width-raw: 1110;
$medium-width-raw: 880;
$content-width-raw: 770;
$form-width-raw: 540;
$page-width: $page-width-raw * 1px;
$medium-width: $medium-width-raw * 1px;
$content-width: $content-width-raw * 1px;
$form-width: $form-width-raw * 1px;

/* ---- Breakpoints ---- */
$bp-mobile-s: "(min-width: 360px)";
$bp-mobile-m: "(min-width: 420px)";
$bp-mobile-l: "(min-width: 540px)";
$bp-tablet: "(min-width: 768px)";
$bp-laptop-s: "(min-width: 1024px)";
$bp-laptop-m: "(min-width: 1200px)";
$bp-laptop-xl: "(min-width: 1366px)";
$bp-laptop-xxl: "(min-width: 1500px)";

$bp-max-mobile-s: "(max-width: 359px)";
$bp-max-mobile-m: "(max-width: 419px)";
$bp-max-mobile-l: "(max-width: 539px)";
$bp-max-tablet: "(max-width: 767px)";
$bp-max-laptop-s: "(max-width: 1023px)";
$bp-max-laptop-m: "(max-width: 1199px)";
$bp-max-laptop-xl: "(max-width: 1365px)";
$bp-max-laptop-xxl: "(max-width: 1499px)";

/* ---- Colors ---- */
$c-white: #fff;
$c-grayFA:#fafafa;
$c-grayE: #eee;
$c-grayE3: #e3e3e3;
$c-grayD9:#d9d9d9;
$c-gray9: #999;
$c-gray80: #808080;
$c-gray6: #666;
$c-gray1: #111;
$c-black:#000;

$c-body: $c-white;
$c-text: $c-black;
$c-text-2: #414141;
$c-theme: #3c2fe9;
$c-theme-dark: #2618e7;
$c-theme-2: #5999ee;
$c-title: $c-black;
$c-dark: #0a2344;
$c-light: #f0f0f0;
$c-highlight:#d4d1fa;
$c-gray: #f5f5f4;

$c-success:#2ea451;
$c-error: #e3000a;
$c-warning: #f26419;
$c-info: $c-theme;

$main-font: 'Inter', sans-serif;
// $secondary-font: 'Roboto Condensed', sans-serif;
