html {
	min-height: 100%;
	height: -webkit-fill-available;
	width: 100%;

	&.open-menu {
		height: 100%;
        overflow: hidden;
	}
}

body {
	background: $c-body;
	min-width: 320px;
	width: 100%;
    overflow-x: hidden;

	.open-menu & {
        height: 100%;
        overflow: visible;
	}
}

.site-wrap {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow-anchor: none;
	position: relative;
}

.container {
	padding: 0 20px;
	position: relative;
	margin: 0 auto;
	max-width: $page-width;
	box-sizing: content-box;

	@media #{$bp-tablet} {
		padding: 0 30px;
	}

    &--medium {
        max-width: $medium-width;
    }

    &--narrow {
        max-width: $content-width;
    }

    &--small {
        max-width: $form-width;
    }

    &--fluid {
        max-width: none;
    }

	.container {
		padding: 0;
	}
}

main {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
}

.main-content {
	position: relative;
	z-index: 1;
	flex: 1 1 auto;

    &:first-child {
        padding-top: 60px;
    }
}

section {
	padding: 3.75em 0;
	position: relative;

    @media #{$bp-laptop-m} {
        padding-top: 7.5em;
        padding-bottom: 7.5em;
    }

    .plain-text & {
        &:not(:first-child) {
            padding-top: 2em;

            @media #{$bp-laptop-m} {
                padding-top: 4em;
            }
        }

        &:not(:last-child) {
            padding-bottom: 2em;

            @media #{$bp-laptop-m} {
                padding-bottom: 4em;
            }
        }
    }

	section {
		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
		}
	}
}

.section {
    &--dark {
        background-color: $c-gray1;
        color: $c-white;
    }
}
