@use 'sass:math';

@mixin orientation($pos) {
  @if $pos == portrait {
    @media screen and (orientation: portrait) { @content; }
  }
  @else if $pos == landscape {
    @media screen and (orientation: landscape) { @content; }
  }
}

@mixin clearfix {
	*zoom:1;
    &:before,
    &:after {
        content: "";
        display: table;
		line-height: 0;
    }
    &:after {
        clear: both;
    }
}

@function color-diff($color-a, $color-b) {
  $hue: hue($color-a) - hue($color-b);
  $saturation: saturation($color-a) - saturation($color-b);
  $lightness: lightness($color-a) - lightness($color-b);

  $function-hue: 'adjust-hue';
  $function-saturation: if($saturation > 0, 'desaturate', 'saturate');
  $function-lightness: if($lightness > 0, 'darken', 'lighten');

  @return (
    #{$function-hue}: -($hue),
    #{$function-saturation}: abs($saturation),
    #{$function-lightness}: abs($lightness),
  );
}

@function apply-color-diff($color, $diff) {
  @each $key, $value in $diff {
    $color: call($key, $color, $value);
  }
  @return $color;
}

@function strip-units($value) {
    @return math.div($value, ($value * 0 + 1));
}

@mixin placeholder {
    $placeholders: ":-webkit-input"":-moz""-moz""-ms-input";

    @each $placeholder in $placeholders {
        &:#{$placeholder}-placeholder {
            @content;
        }
    }
}

@mixin resp($property, $min, $max, $rangeMin: 320px, $rangeMax: $page-width + 30) {
    $diff: strip-units($max - $min);
    $diffRange: strip-units($rangeMax - $rangeMin);
    #{$property}: calc(#{$min} + #{$diff} * ((100vw - #{$rangeMin}) / #{$diffRange}));

    @media (max-width: $rangeMin) {
        #{$property}: $min;
    }

    @media (min-width: $rangeMax) {
        #{$property}: $max;
    }
}

@mixin inverseResp($property, $max, $min, $rangeMin: 320px, $rangeMax: $page-width + 30) {
    $diff: strip-units($max - $min);
    $diffRange: strip-units($rangeMax - $rangeMin);
    #{$property}: calc(#{$max} - #{$diff} * ((100vw - #{$rangeMin}) / #{$diffRange}));

    @media (max-width: $rangeMin) {
        #{$property}: $max;
    }

    @media (min-width: $rangeMax) {
        #{$property}: $min;
    }
}
