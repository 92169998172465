html {font-size: 100%;}

body {
	color: $c-text;
	font-family: $main-font;
	font-size: 16px;
	line-height: 1.2;
	text-size-adjust: 100%;
}

::selection {
	background: $c-theme;
	color: $c-white;
	text-shadow: none;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight: 900;
	margin: 1.5em 0 1em;

	&:first-child {margin-top: 0;}
	&:last-child {margin-bottom: 0;}
}

h1, .h1 {
	@include resp(font-size, 52px, 120px, 360px, 1600px);
}

h2, .h2 {
    @include resp(font-size, 36px, 96px, 360px, 1600px);
}

h3, .h3 {
	@include resp(font-size, 28px, 80px, 360px, 1600px);
}

h4, .h4 {
 	font-size: 1.125em;
}

h5, .h5 {
	font-size: 1em;
}

h6, .h6 {
	font-size: .875em;
}

p {
	font-size: 1em;
	line-height: 1.5;
	margin-bottom: 1.5em;

	&:last-child {
		margin-bottom: 0;
	}

	a, a:visited {
		&:hover, &:active {
			text-decoration: none;
		}
	}
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid $c-light;
	margin: 2em 0;
	padding: 0;
	clear: both;
}

dl {
    line-height: 1.2;
	font-size: .875em;

    a, a:visited {
        transition: color .2s;

        &:hover, &:active {
            text-decoration: none;
            color: $c-theme;
        }
    }
}

dt, dd {
    display: inline;
    vertical-align: baseline;
}

dt {
   	display: inline-block;

	&:not(:first-child) {
		margin-top: 1em;
	}
}

dd {
    &:after {
        content: "\A";
        white-space: pre;
    }
}

.section-header {
    color: $c-theme;

    h2, .h2 {
        color: inherit;
    }
}
