.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    text-align: center;
    padding: 10px 0;
    min-height: 60px;
    color: $c-white;
    background-color: transparent;
    transform: translateY(-100%);
    background-color: $c-gray1;
    border-bottom: 1px solid hsla(0,0%,100%,.1);
    box-shadow: -3px -3px 6px 8px rgba(0,0,0,.05);

    .container {
        max-width: none;
        min-height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media #{$bp-tablet} {
            &:before {
                content: "";
                display: block;
                flex: 1 1 0px;
                margin-right: 14px;
            }
        }
    }

    &__logo {
        font-size: 26px;
        font-weight: 600;
        line-height: 1;

        a {
            height: 26px;
            display: inline-flex;
            align-items: center;
            padding-bottom: 4px;

            img {
                max-height: 100%;
                width: auto;
                vertical-align: baseline;
            }
        }
    }

    .hamburger {
        line-height: 1;
        margin-right: -8px;
    }
}

.menu {
    margin-left: 14px;

    @media #{$bp-tablet} {
        flex: 1 1 0px;
        text-align: right;
    }
}

.main-menu {
    text-align: center;
    color: $c-white;
    font-weight: 900;
    @include resp(font-size, 28px, 70px, 360px, 1600px);

    ul {
        overflow: hidden;
    }

    a {
        display: block;
        padding: .25em 0;
        transition: transform .35s ease;
        will-change: transform;

        &:hover, &:active {
            text-decoration: none;
            transform: scale(1.05);
        }
    }
}

.headroom {
    &--top {
        transform: translateY(0);

        &:not(.open-menu &) {
            background-color: transparent;
            border-bottom-color: transparent;
            box-shadow: none;
        }
    }

    &--not-top, &--pinned, &--unpinned {
        transition: background-color .4s ease, transform .4s ease;
    }

    &--pinned {
        transform: translateY(0);
    }

    &--unpinned {
        transform: translateY(-100%);
    }
}
