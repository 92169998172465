.drawer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 9;
    background-color: $c-gray1;
    overflow-y: auto;
    opacity: 0;
    transform: translateY(-100vh);
    transition: transform .6s ease-in-out, opacity .6s ease-in-out;

    .open-menu & {
        transform: translateY(0);
        opacity: 1;
    }

    &-content {
        padding: 100px 20px;
    }
}
