html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
	overflow-x: hidden;
	scroll-behavior: smooth;

	&.ua-ios {
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}
}

*, *:after, *:before {
	box-sizing: border-box;
}

* {
	margin:0;
	padding:0;
	-webkit-tap-highlight-color: transparent;
	-webkit-tap-highlight-color: rgba(255,255,255,0);
}

a, a:visited {
	outline: none;
	text-decoration: none;
	color: inherit;

	&:hover, &:active {
		text-decoration: underline;
	}

	img {
		border: none;
		outline: none;
	}
}

img {
	max-width: 100%;
	height: auto;
}

ul {
	list-style: none;
}

a, button, input, select, textarea, label, summary {
	touch-action: manipulation;
}

audio, canvas, img, svg, video {
	vertical-align: middle;
}

header, footer, nav, article, aside, section, main {
	display: block;
}