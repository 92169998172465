.block-title {
    text-align: center;

    &:not(:last-child) {
        margin-bottom: 43px;

        .article--xs & {
            @media #{$bp-max-tablet} {
                margin-bottom: 32px;
            }
        }
    }

    &__title {
        @include resp(font-size, 36px, 96px, 360px, 1600px);
        line-height: 1;
        margin: 0;
        color: $c-title;

        .section--dark & {
            color: inherit;
        }

        &--large {
            @include resp(font-size, 52px, 120px, 360px, 1600px);
        }

        &--small {
            @include resp(font-size, 28px, 80px, 360px, 1600px);
        }

        &--xs {
            font-weight: bold;
            @include resp(font-size, 26px, 32px, 360px, 1600px);
        }
    }

    &__subtitle {
        @include resp(font-size, 22px, 32px, 360px, 1600px);

        .container:not(:first-child) & {
            margin-top: 20px;
        }

        .article & {
            p, ul, ol, dl {
                font-size: inherit;
            }
        }
    }
}


.article {
    p, ul, ol, dl {
        @include resp(font-size, 20px, 30px, 360px, 1600px);
        line-height: 1.5333;

        &:not(:last-child) {
            margin-bottom: 1.4333em;
        }
    }

    ul {
        list-style: disc;
        padding-left: 2.5em;

        li {
            padding-left: .5em;
        }

        &.list--check {
            list-style: none;
            padding-left: 0;

            li {
                position: relative;
                padding-left: 1.8333em;

                &:not(:last-child) {
                    margin-bottom: 1.3333em;
                }

                &:before {
                    content: "";
                    background-image: url('../images/check-circle(3).svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center center;
                    width: 1.2em;
                    height: 1.2em;
                    position: absolute;
                    left: 0;
                    top: .166667em;
                }
            }
        }
	}

    em {
        background-color: $c-highlight;
        font-style: normal;
    }

    .button-row {
        text-align: center;
    }

    figure {
        margin-bottom: 1.5em;
    }

    a:not(.button) {
        color: $c-theme;
        text-decoration: underline;

        &:visited {
            color: $c-theme-dark;
        }

        &:hover, &:active {
            text-decoration: none;
        }
    }

    &--xs {
        p, ul, ol, dl {
            @include resp(font-size, 16px, 22px, 360px, 1600px);
        }
    }
}
