.footer {
    background-color: $c-black;
    color: $c-white;

    &__section {
        padding: 3em 0;
    }

    &__cols {
        @media #{$bp-mobile-l} {
            display: flex;
            justify-content: space-between;
            margin: 0 -15px;
        }
    }

    &__column {
        &:not(:first-child) {
            margin-top: 2em;

            @media #{$bp-mobile-l} {
                margin-top: 0;
            }
        }

        @media #{$bp-mobile-l} {
            padding: 0 15px;
            flex: 0 1 auto;
        }

        @media #{$bp-laptop-m} {
            flex: 1 0 auto;
        }

        li {
            margin-bottom: 10px;
        }
    }

    &__logo {
        margin-bottom: 18px;
        line-height: 1;

        a {
            height: 22px;
            display: inline-block;

            img {
                max-height: 100%;
                width: auto;
                vertical-align: baseline;
            }
        }
    }

    &__text, li {
        font-size: 14px;
        line-height: 1.5;
    }

    &__title {
        margin-bottom: 26px;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
    }

    &__bottom {
        padding: 14px 0;
        font-size: 14px;
        line-height: 1.5;

        .container {
            @media #{$bp-laptop-s} {
                display: flex;
            }
        }

        &-nav {
            margin-top: 16px;

            @media #{$bp-tablet} {
                display: flex;
            }

            @media #{$bp-laptop-s} {
                margin-top: 0;
            }

            li {
                @media #{$bp-tablet} {
                    margin-left: .375em;
                }

                &:before {
                    @media #{$bp-tablet} {
                        content: "|";
                        vertical-align: baseline;
                        margin-right: .375em;
                    }
                }

                &:first-child {
                    @media #{$bp-max-laptop-s} {
                        margin-left: 0;

                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
