.hero {
    padding: 0;

    &:not(:last-child) {
        clip-path: polygon(0 0, 100% 0, 100% 100vh, 0 100%);

        &.hero--short {
            clip-path: polygon(0 0, 100% 0, 100% 66.6667vh, 0 100%);
        }
    }

	figure {
		position: relative;
	}

	.container {
		padding: 0;
		height: 100%;
	}

	figcaption {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 20px;
		color: $c-white;
		text-align: center;
        background-color: rgba($c-black, .7);
		transition: opacity 1s;

		.container {
			width: 100%;
			height: auto;
		}

		&.ready {
			.block-title__title, .block-title__subtitle, .button-row {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}

    &.loading {
        figcaption {
            opacity: 0;
        }
    }
}

.hero__wrapper {
	position:relative;

	&:after {
		content: "";
		display: block;
		padding-top: calc(100vh + 90px);

        .hero--short & {
            padding-top: calc(66.6667vh + 90px);
        }
	}

	figure, figcaption {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}

	figcaption {
		z-index: 2;
	}

	img {
		display: none;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		position: relative;
		z-index: 0;
	}

    .block-title__title, .block-title__subtitle, .button-row {
        color: inherit;
        opacity: 0;
		transform: translateY(20px);
		transition: opacity .5s, transform .8s ease-out;
    }

    .block-title__subtitle {
        transition-delay: .25s;
    }

	.button-row {
		transition-delay: .5s;
	}
}
