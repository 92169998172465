fieldset {
	border:none;
	min-width:0;

	& + & {margin-top: 2.25em;}

	&.infoFields + & {
		margin-top: 1.5em;
	}
}

label {
	font-weight:normal;
	display:block;
	font-size:1em;
	font-weight:normal;
	margin-bottom: .375em;

	&.required:after, &[required]:after {
		content:"\00a0*";
		color: $c-theme;
	}

	&.inline {
		display:inline-block;
		margin-right: 1em;

		&:last-child {
			margin-right: 0;
		}
	}

	&.option {
		position: relative;

		&:last-child {margin-bottom: 0;}
	}

	a, a:visited {
		color:$c-theme;
		text-decoration: underline;

		&:hover, &:active {
			text-decoration: none;
		}
	}

	form & small {
		margin: 0;
		display: inline;
	}
}

input[type=text], input[type=password], input[type=tel], input[type=email], input[type=number], input[type=search], input[type=date], select, textarea, .input {
	border:1px solid #c1cbd4;
	padding:.625em 1em;
	height:2.625em;
	font-family:$main-font;
	font-size:1em;
	line-height:1.25;
	color:$c-text;
	background-color:$c-grayFA;
	width:100%;
	display:inline-block;
	border-radius:4px;
	transition:box-shadow .2s;

	&:focus {
		outline:none;
		background-color: $c-white;
		box-shadow: 0 0 2px 0 $c-theme;
	}

	&.error, &:invalid {
		box-shadow: 0 0 1px 1px $c-error;
		border-color:$c-error;
	}

	&.success, &.green {
		box-shadow: 0 0 0 2px rgba($c-success,.5);
		border-color:$c-success;
	}

	&[disabled] {
		background-color:$c-grayD9;
		cursor:not-allowed;
	}

	&.small {
		font-size: .875em;
		line-height: 18px;
		height: 2em;
		padding: 6px .5em;
		padding-right: 2.25em;
	}

	&.auto-w, label.inline + &.auto-w {
		width: auto;
		max-width: none;
	}

	label.inline + & {
		width: auto;
		margin-left: .25em;
		max-width: 120px;

    .filters & {
      margin-left: 0;
      max-width: none;
    }
	}
}

input[type=color]{
	display: none;
}

input[type=number]:not(.show-controls) {
	-moz-appearance: textfield;
}

input[type=number]:not(.show-controls)::-webkit-inner-spin-button, input[type=number]:not(.show-controls)::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/*input[type=date]::-webkit-inner-spin-button, input[type=date]::-webkit-calendar-picker-indicator {
	-webkit-appearance: none;
	display: none;
}*/

select {
	-webkit-appearance:none;
	-moz-appearance:none;
	background-image:url('../images/chosen-dropdown-caret.svg');
	background-position:right 18px center;
	background-repeat:no-repeat;
  padding-right: 2.25em;

	.ua-trident & {
		background-image: none;
	}

	&:-moz-focusring {
		color: transparent;
    	text-shadow: 0 0 0 #000;
	}

	.ua-gecko & {
		padding-top:6px;
		padding-bottom:8px;
		padding-left:12px;
	}

	&.small {
		background-position: right 8px center;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 2em;

		.ua-gecko & {
			padding: 4px 2em 4px .5em;
		}
	}

	&[disabled], &[readonly], &[readonly="readonly"] {background-image:none;}
}

option {
	padding:.1em .75em;

	&:focus {outline:none;}
}

textarea {
	resize:vertical;
	overflow:auto;
	height:auto;
}

input[type=radio], input[type=checkbox] {
	vertical-align:baseline;
	position: relative;
	top:1px;

	.inline &, .option &, label & {
		margin-right:.5em;

		&:last-child {margin:0 0 0 .5em;}
	}
}

.file-upload input[type=file] {
	position: absolute;
	top:0;
	left:-9999px;
}

::-webkit-input-placeholder {opacity:.5; color:inherit;}
:-moz-placeholder {opacity:.5; color:inherit;}
::-moz-placeholder {opacity:.5; color:inherit;}
:-ms-input-placeholder {opacity:1; color:inherit;}

.file-upload {
	@media (min-width:390px) {
		display: flex;
	}

	button {
		width: 100%;
		margin-top: .75em;

		@media (min-width:390px) {
			width: auto;
			margin-top: 0;
		}

		@media #{$bp-mobile-l} {
			margin-left: 1em;
		}

		span {
			min-width: 0;
		}
	}

	input[disabled]{
		background-color:inherit;
	}

  .clearable {
    position: relative;
    flex: 1 1 auto;
  }

  .clear-btn {
    position: absolute;
    right: .5em;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    font-size: 1.5em;
    line-height: 1;
    display: none;

    i {
      vertical-align: bottom;
      line-height: 1;
    }
  }
}

form {
	.form-row {
		margin:0;
		position: relative;
		clear: both;

		@media #{$bp-tablet} {
			margin:1.25em -10px;
			display:flex;
			justify-content:center;
			align-items: flex-end;

			&:first-child {margin-top: 0;}
			&:last-child {margin-bottom: 0;}
		}

		&:last-child {
			.field:last-child {
				margin-bottom:0;
			}
		}

		.field {
			margin-top:1.25em;
			margin-bottom:1.25em;
			position: relative;

			&:first-child {margin-top: 0;}

			@media #{$bp-tablet} {
				margin:0;
				padding:0 10px;
				flex:1 1 0px;

				small {left:10px;}

				&.double-w {
					flex-grow:2;
				}

				&.triple-w {
					flex-grow:3;
				}

				&.inline {
					display: flex;
					align-items:baseline;

					div {
						flex:1 1 100%;
					}
				}
			}
		}
	}

	small {
		font-size: .875em;
		text-align: left;
		display: block;
		margin-top: .5em;
		line-height: 1.5;

		a, a:visited {
			color:$c-warning;
			font-weight:bold;
			text-decoration: underline;

			&:hover, &:active {
				text-decoration: none;
			}
		}

		&.error {
			color:$c-error;
		}

    &.fwn {
      color: $c-gray6;
    }
	}

  .filepond + small, .filepond--root + small {
    font-size: .8125em;
    font-weight: normal;
  }
}

/* fancy forms */
.form-group {
	margin: 0 -1em;
	flex: 1 1 auto;

	@media #{$bp-tablet} {
		display: flex;
	}

	> * {
		margin:0 1em;
		flex:1 1 0px;

		&.double {flex-grow:2;}
		&.triple {flex-grow:3;}

		&.auto-w {
			flex:0 0 auto;
		}

		&.inline {
			flex: 0 0 auto;
			padding: 0;
			align-self: center;
			margin-bottom: .375em;

			&:last-child {
				margin-bottom:2em;

				@media #{$bp-tablet} {
					margin-bottom: .375em;
				}
			}

			@media #{$bp-tablet} {
				&:not(:last-child){
					margin-right: 0;
				}

				&:not(:first-child){
					margin-left:0;
				}
			}
		}

		&.field {
			align-self: center;
		}

		&.button {
			@media #{$bp-tablet} {
				align-self: flex-start;
				margin-top: 12px;
				margin-left: 0;
				border-width: 2px;
			}
		}

		.button.large {
			span {
                font-size: 1em;
				line-height: 22px;
				min-width: 0;
                padding: 12px 2.25em;
			}
		}
	}
}

.text_input_field {
	position: relative;
	padding:.75em 0 1.125em;

	label {
		position: absolute;
		left: .4444em;
		top: 1.3333em;
		font-size: 1.5em;
		line-height: 1.3333;
		max-width: 100%;
		margin: 0 0 0 -.25em;
		padding: 0 .25em;
		color:$c-gray6;
		background-color: transparent;
		pointer-events: none;
		transition-duration: .15s;
		transition-timing-function: ease-in-out;
		transition-property: opacity, font-size, transform, background-color, color;
		opacity: .5;
	}

	input, textarea, select {
		border-width: 2px;
		line-height: 1.3333;
		font-size: 1.5em;
		padding: .7778em .4444em;
		background-color: $c-white;
		color:$c-gray6;
		border-color: #c1cbd4;
		transition: border-color .15s ease-out;
		border-radius: .2em;

		&:hover {
			border-color:darken($c-grayE3,7);
		}

		&:focus, &[required]:valid, &:not(:placeholder-shown), .ua-android.ua-chrome &:invalid:placeholder-shown, .ua-android.ua-chrome &:valid {
			~ label {
				opacity: 1;
				font-size: .875em;
				transform: translate(7px,-17px);
				background-color: $c-white;
				color:$c-theme;
			}

			&[disabled] {
				~ label {
					background-color: transparent;
					transform: translateY(-26px);
				}
			}
		}

		.ua-trident &, .ua-edge & {
			&:focus, &:valid, &:invalid {
				~ label {
					opacity: 1;
					font-size: .8125em;
					transform: translateY(-18px);
					background-color: $c-white;
					color:$c-theme;
				}
			}
		}

		&:focus {
			box-shadow: none;
			border-color:$c-theme;
			outline: none;
		}

		&:valid {
			~ small {
				//display: none;
			}
		}

		&:invalid {
			outline: none;
			box-shadow: none;
			border-color: #c1cbd4;

			&:-ms-input-placeholder {opacity:1;}

			&:focus {
				border-color:$c-theme;
			}

			&:not(:placeholder-shown){
				border-color: firebrick;
			}
		}

		&.error {
			border-color:$c-error;
		}

		&[disabled]:not([name="filename"]) {
			background-color: $c-grayD9;
		}

		&::-webkit-input-placeholder {opacity:0; color:transparent;}
		&:-moz-placeholder {opacity:0; color:transparent;}
		&::-moz-placeholder {opacity:0; color:transparent;}
		&:-ms-input-placeholder {opacity:0; color:transparent;}
	}

	input {
		height:3em;
	}

	select {
		height: 3em;
		padding: .675em 2em .675em .3333em;
		min-width: 72px;

    &:invalid {
      color: #b2b2b2;

      &:not(:placeholder-shown) {
        border-color: #c1cbd4;
      }
    }

		option {
      padding-bottom: 0;
    }
	}

	small {
		position:absolute;
		left:1px;
		bottom:-1px;
		line-height: 1.5;
		margin: 0;
		color:$c-gray6;
		white-space: nowrap;
    font-size: .75em;
    font-weight: normal;
	}
}

.option_field {
	padding:0 0 .75em;
}

.form-footer {
	text-align: center;
	margin-top: 1.25em;

	a, a:visited {
		color:$c-theme;
	}
}

@-webkit-keyframes autofill {
    to {
        color: #666;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

@media(max-width:344px){
	.g-recaptcha > div {
		width: 100% !important;
	}

	.g-recaptcha iframe {
		width: 100% !important;
	}
}

.form {
    &__note {
        font-size: .8125em;

        &.danger {
            color: $c-error;
        }

        .form-group + & {
            margin-top: -6px;
        }

        small {
            font-size: inherit;
            margin: 0;
        }
    }
}
